<template>
  <div>
    <div class="row mb-10">
      <div class="col-xl-8 pl-0">
        <!-- <img src="media/images/adidas.svg" /> -->
        <img class="logo" :src="client_detail.logo" />
        <h1 class="ml-4 main-header">
          <b>{{ client_detail.name }} Coupons</b>
        </h1>

        <v-btn
          class="mx-1 ml-4 mr-4 mdi-plus-bg"
          @click="createCoupon"
          fab
          dark
          color="gainsboro"
        >
          <v-icon class="mdi-plus-content">mdi-plus</v-icon>
        </v-btn>
      </div>

      <div class="col-xl-4">
        <div>
          <!-- <b-form-group
            id="fieldset-start-date"
            label-cols-sm="4"
            label-cols-lg="6"
            content-cols-sm
            content-cols-lg="10"
            label-for="date"
          >
            <b-form-datepicker
              id="date"
              v-model="date"
              @context="onSelectStartDate"
              placeholder="1/10/2021"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            ></b-form-datepicker>
          </b-form-group> -->

          <div style="float: right">
            <label for="check-button">
              <h6 style="padding-top: 3px">
                <b>Switch to Calendar view</b>
              </h6>
            </label>
            <b-form-checkbox
              name="check-button"
              size="lg"
              switch
              v-model="show_calendar"
              class="switch-to-calendar-button"
            >
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <!-- ACTIVE COUPONS SERIES -->
    <div v-if="!show_calendar">
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Active Coupons Series
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  :current-page="active_current_page"
                  :per-page="active_per_page"
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="cancellationFields"
                  :items="activeCouponSeries"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllActivePayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedActiveCoupon.length ===
                          activeCouponSeries.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedActiveCoupon"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>

                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        @click="assignCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/assign.png" />
                        <br />
                        <b>ASSIGN</b>
                      </b-col>
                      <b-col
                        cols="auto"
                        @click="editCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        v-b-modal.delete-payout
                        @click="couponSeriesToDel = slot.item"
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col @click="copyCoupon(slot.item)">
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-slot:cell(dates)="slot">
                    <div>
                      <span style="line-height: 1.5;">
                        {{ slot.item.from_date | moment("DD MMM, YYYY") }} -
                      </span>
                      <br />
                      <span v-if="slot.item.to_date" style="line-height: 1.5;">
                        {{ slot.item.to_date | moment("DD MMM, YYYY") }}</span
                      >
                      <span v-else style="line-height: 1.5;"> onwards </span>
                    </div>
                  </template>
                  <template v-slot:cell(channels)="slot">
                    <div>
                      <span
                        v-html="slot.item.channels.split(',').join(',<br>')"
                        style="line-height: 1.5;"
                      >
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(limitations)="slot">
                    <div style="width:180px">
                      <span>
                        {{ slot.item.limitations }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(region)="slot">
                    <div>
                      <span v-html="slot.item.region"> </span>
                    </div>
                  </template>
                </b-table>
                <div
                  class="no-records"
                  v-if="!activeCouponSeries || !activeCouponSeries.length"
                >
                  No Records to show
                </div>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="active_current_page"
                      pills
                      :total-rows="activeRows"
                      :per-page="active_per_page"
                      class="b-pagiantion-button"
                    ></b-pagination>
                  </b-col>
                  <b-col style="margin-right: 30px">
                    <b-button
                      class="deleteAll-btn"
                      v-b-modal.deleteAll-payout
                      :disabled="!selectedActiveCoupon.length"
                      @click="deleteAllConfirmation('active')"
                      cols="auto"
                      >Delete All</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- UPCOMING COUPONS SERIES -->
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Upcoming Coupons Series
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table
                  :current-page="upcoming_current_page"
                  :per-page="upcoming_per_page"
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="cancellationFields"
                  :items="upcomingCouponSeries"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllUpcomingPayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedUpcomingCoupon.length ===
                          upcomingCouponSeries.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedUpcomingCoupon"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>

                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        @click="assignCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/assign.png" />
                        <br />
                        <b>ASSIGN</b>
                      </b-col>
                      <b-col
                        cols="auto"
                        @click="editCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        v-b-modal.delete-payout
                        @click="couponSeriesToDel = slot.item"
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col @click="copyCoupon(slot.item)">
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-slot:cell(dates)="slot">
                    <div>
                      <span style="line-height: 1.5;">
                        {{ slot.item.from_date | moment("DD MMM, YYYY") }} -
                      </span>
                      <br />
                      <span v-if="slot.item.to_date" style="line-height: 1.5;">
                        {{ slot.item.to_date | moment("DD MMM, YYYY") }}</span
                      >
                      <span v-else> onwards</span>
                    </div>
                  </template>
                  <template v-slot:cell(channels)="slot">
                    <div>
                      <span
                        v-html="slot.item.channels.split(',').join(',<br>')"
                        style="line-height: 1.5;"
                      >
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(limitations)="slot">
                    <div style="width:180px">
                      <span>
                        {{ slot.item.limitations }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:cell(region)="slot">
                    <div>
                      <span v-html="slot.item.region"> </span>
                    </div>
                  </template>
                </b-table>
                <div
                  class="no-records"
                  v-if="!upcomingCouponSeries || !upcomingCouponSeries.length"
                >
                  No Records to show
                </div>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="upcoming_current_page"
                      pills
                      :total-rows="upcomingRows"
                      :per-page="upcoming_per_page"
                      class="b-pagiantion-button"
                    ></b-pagination>
                  </b-col>
                  <b-col style="margin-right: 30px">
                    <b-button
                      class="deleteAll-btn"
                      v-b-modal.deleteAll-payout
                      :disabled="!selectedUpcomingCoupon.length"
                      @click="deleteAllConfirmation('upcoming')"
                      cols="auto"
                      >Delete All</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PAST COUPONS SERIES -->
      <h2 style="font-weight: bold; margin-bottom: 20px">
        Past Coupons Series
      </h2>
      <div class="row mb-20">
        <div class="col-xl-12">
          <div
            class="
              card card-custom card-stretch
              gutter-b
              card-shadowless
              my-class-shadow
            "
          >
            <div class="card-body py-0">
              <div style="width: 100%">
                <b-table

                  :current-page="past_current_page"
                  :per-page="past_per_page"
                  class="ag-theme-material"
                  sticky-header
                  style="width: 100%; margin-top: 20px"
                  hover
                  :fields="cancellationFields"
                  :items="pastCouponSeries"
                >
                  <!-- A custom formatted header cell for select all checkbox -->
                  <template #head(index)="">
                    <b-form-checkbox
                      @change="selectAllPastPayout"
                      class="custom-control custom-checkbox checkbox-xl"
                      :checked="
                        selectedPastCoupon.length === pastCouponSeries.length
                      "
                    ></b-form-checkbox>
                  </template>

                  <!-- checkbox slot for each row -->
                  <template v-slot:cell(index)="slot">
                    <b-form-checkbox
                      name="selected-items"
                      class="custom-control custom-checkbox checkbox-xl"
                      v-model="selectedPastCoupon"
                      :value="slot.item"
                    >
                    </b-form-checkbox>
                  </template>

                  <!-- action slot for each row -->
                  <template v-slot:cell(action)="slot">
                    <b-row>
                      <b-col
                        cols="auto"
                        @click="assignCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/assign.png" />
                        <br />
                        <b>ASSIGN</b>
                      </b-col>
                      <b-col
                        cols="auto"
                        @click="editCancellation(slot.item._id)"
                      >
                        <b-avatar role="button" src="media/images/edit.png" />
                        <br />
                        <b>EDIT</b>
                      </b-col>
                      <b-col
                        v-b-modal.delete-payout
                        @click="couponSeriesToDel = slot.item"
                        cols="auto"
                      >
                        <b-avatar role="button" src="media/images/cancel.png" />
                        <br />
                        <b>CANCEL</b>
                      </b-col>
                      <b-col @click="copyCoupon(slot.item)">
                        <b-avatar role="button" src="media/images/copy.png" />
                        <br />
                        <b>COPY</b>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-slot:cell(dates)="slot">
                    <div>
                      <span style="line-height: 1.5;">
                        {{ slot.item.from_date | moment("DD MMM, YYYY") }} -
                      </span>
                      <br />
                      <span v-if="slot.item.to_date" style="line-height: 1.5;">
                        {{ slot.item.to_date | moment("DD MMM, YYYY") }}</span
                      >
                      <span v-else style="line-height: 1.5;">
                        onwards
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(channels)="slot">
                    <div>
                      <span
                        v-html="slot.item.channels.split(',').join(',<br>')"
                        style="line-height: 1.5;"
                      >
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(limitations)="slot">
                    <div style="width:180px">
                      <span>
                        {{ slot.item.limitations }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(region)="slot">
                    <div>
                      <span v-html="slot.item.region"> </span>
                    </div>
                  </template>
                </b-table>
              </div>
              <div
                class="no-records"
                v-if="!pastCouponSeries || !pastCouponSeries.length"
              >
                No Records to show
              </div>
              <b-row>
                <b-col>
                  <b-pagination
                    v-model="past_current_page"
                    pills
                    :total-rows="pastRows"
                    :per-page="past_per_page"
                    class="b-pagiantion-button"
                  ></b-pagination>
                </b-col>
                <b-col style="margin-right: 30px">
                  <b-button
                    class="deleteAll-btn"
                    v-b-modal.deleteAll-payout
                    :disabled="!selectedPastCoupon.length"
                    @click="deleteAllConfirmation('past')"
                    cols="auto"
                    >Delete All</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CouponCalendar v-if="show_calendar" :clientId="this.$route.params.id">
    </CouponCalendar>

    <b-modal
      id="delete-payout"
      :title="'Confirmation for Cancellation ' + couponSeriesToDel.name"
      ok-variant="danger"
      ok-title="Remove"
      @ok="deletecouponseries"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete this coupon series?</h5>
    </b-modal>
    <b-modal
      id="deleteAll-payout"
      :title="'Confirmation for Coupon '"
      ok-variant="danger"
      ok-title="Remove"
      @ok="delAll"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete all following coupons series?</h5>
      <ul style="margin-left: 20px" v-if="findPayout == 'active'">
        <li v-for="(item, i) in selectedActiveCoupon" v-bind:key="i">
          {{ item.series_name }}
        </li>
      </ul>
      <ul style="margin-left: 20px" v-if="findPayout == 'upcoming'">
        <li v-for="(item, i) in selectedUpcomingCoupon" v-bind:key="i">
          {{ item.series_name }}
        </li>
      </ul>
      <ul style="margin-left: 20px" v-if="findPayout == 'past'">
        <li v-for="(item, i) in selectedPastCoupon" v-bind:key="i">
          {{ item.series_name }}
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../core/services/api.service";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import CouponCalendar from "./coupon-series-calendar.vue";
export default {
  components: {
    Dropdown7,
    CouponCalendar,
  },
  computed: {
    ...mapGetters(["ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
  },
  props: ["coupons"],
  data() {
    return {
      activeRows: 0,
      pastRows: 0,
      upcomingRows: 0,
      activeCurrentPage: 1,
      date: new Date().toISOString().substring(0, 7),
      pagination: [],
      searchQuery: "",
      maxPageNumbers: 10,
      selectedActiveCoupon: [],
      selectedUpcomingCoupon: [],
      selectedPastCoupon: [],
      currentRowIndex: -1,
      cancellationFields: [
        {
          key: "index",
          label: "",
        },
        "series_name",
        "type",
        "channels",
        "region",
        "limitations",
        "dates",
        "action",
      ],
      activeCouponSeries: [],

      upcomingCouponSeries: [],
      pastCouponSeries: [],
      cancellationPayload: {},
      couponSeriesToDel: {},
      findPayout: "",
      show_calendar: false,
      active_current_page: 1,
      active_per_page: 5,
      active_rows: 0,
      past_current_page: 1,
      past_per_page: 5,
      past_rows: 0,
      upcoming_current_page: 1,
      upcoming_per_page: 5,
      upcoming_rows: 0,
    };
  },
  mounted() {
    // if user refresh this page directly so we've to hit api again to get client details.
    if (!this.client_detail || !this.client_detail.length) {
      this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
    }
    localStorage.removeItem("copyCoupon"),
      (this.cancellationPayload = {
        client_id: this.$route.params.id,
        month: this.date.split("-")[1],
        year: this.date.split("-")[0],
      });
    this.getActiveCouponsSeries();
    this.getPastCouponsSeries();
    this.getUpcomingCouponsSeries();
  },
  methods: {
    numerical_component_value(value) {
      return "x".repeat(value);
      let res = "";
      for (let i = 1; i <= value; i++) {
        res = res + i;
      }
      return res;
    },
    dateChanged(e) {
      this.date = e.target.value;
    },
    selectAllActivePayout() {
      if (this.selectedActiveCoupon.length === this.activeCouponSeries.length) {
        this.selectedActiveCoupon = [];
      } else {
        this.selectedActiveCoupon = this.activeCouponSeries.slice();
      }
    },
    selectAllPastPayout() {
      if (this.selectedPastCoupon.length === this.pastCouponSeries.length) {
        this.selectedPastCoupon = [];
      } else {
        this.selectedPastCoupon = this.pastCouponSeries.slice();
      }
    },
    selectAllUpcomingPayout() {
      if (
        this.selectedUpcomingCoupon.length === this.upcomingCouponSeries.length
      ) {
        this.selectedUpcomingCoupon = [];
      } else {
        this.selectedUpcomingCoupon = this.upcomingCouponSeries.slice();
      }
    },
    copyCoupon(coupon) {
      localStorage.setItem("copyCoupon", coupon._id),
        this.$router.push({
          name: "create-coupon",
          params: { id: this.$route.params.id },
          query: { copy_page: true },
        });
    },

    createCoupon() {
      this.$router.push({
        name: "create-coupon",
        params: { id: this.$route.params.id },
      });
    },
    async deletecouponseries() {
      try {
        await ApiService.post("/coupons/coupon-series/delete", {
          coupon_series_ids: [this.couponSeriesToDel._id],
        });
        this.getActiveCouponsSeries();
        this.getPastCouponsSeries();
        this.getUpcomingCouponsSeries();
      } catch (err) {
        console.log(err);
      }
    },

    editCancellation(couponId) {
      this.$router.push({
        name: "edit-coupon",
        params: { id: couponId },
        query: { name: this.client_detail.name },
      });
    },
    assignCancellation(couponId) {
      this.$router.push({
        name: "assign-coupon",
        params: { id: couponId },
        query: { name: this.client_detail.name },
      });
    },
    formatRules(payload) {
      let res = "";
      if (payload.channels && payload.channels.length) {
        res = "Channels";
      }
      if (payload.region && payload.region.length) {
        res = res.concat(" Regions");
      }
      if (payload.coupons && payload.coupons.length) {
        res = res.concat(" coupons");
      }
      if (payload.payout_rules || payload.rules) {
        res = res.concat(" RevSlab");
      }
      return res;
    },

    getActiveCouponsSeries() {
      ApiService.post(
        "/coupons/coupon-series/active-series",
        this.cancellationPayload
      )
        .then((response) => {
          console.log("MAKE", response.data.data);
          this.activeCouponSeries = response.data.data.map((res) => {
            console.log("op", res);
            // ADD CHUNKS
            var perChunk = 2; // items per chunk
            var inputArray = res.regions;
            var result = inputArray.reduce((resultArray, item, index) => {
              const chunkIndex = Math.floor(index / perChunk);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }

              resultArray[chunkIndex].push(item);
              console.log(resultArray, "resultArray");
              return resultArray;
            }, []);

            console.log("resultresultresult", result); // result: [['a','b'], ['c','d'], ['e']]

            let options = { day: "numeric", month: "short", year: "numeric" };
            let from_date = new Date(res.from_date);
            let to_date = res.to_date ? new Date(res.to_date) : "";
            from_date = from_date.toLocaleDateString("en-US", options);
            to_date = to_date
              ? to_date.toLocaleDateString("en-US", options)
              : "";
            var coupounNameVal = "";
            if (res.numerical_component !== "0") {
              coupounNameVal =
                res.series_name +
                this.numerical_component_value(res.numerical_component);
            } else {
              coupounNameVal = res.series_name;
            }
            return {
              ...res,
              series_name: coupounNameVal,
              dates: `${from_date} ${to_date ? "-" + to_date : ""}`,
              region: res.regions.join(),
              rules: this.formatRules(res),
              channels:
                res.channels && Array.isArray(res.channels)
                  ? res.channels.join()
                  : res.channels,
            };
          });
          this.activeRows = this.activeCouponSeries.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUpcomingCouponsSeries() {
      ApiService.post(
        "/coupons/coupon-series/upcoming-series",
        this.cancellationPayload
      )
        .then((response) => {
          this.upcomingCouponSeries = response.data.data.map((res) => {
            let options = { day: "numeric", month: "short", year: "numeric" };
            let from_date = new Date(res.from_date);
            let to_date = res.to_date ? new Date(res.to_date) : "";
            from_date = from_date.toLocaleDateString("en-US", options);
            to_date = to_date
              ? to_date.toLocaleDateString("en-US", options)
              : "";

            return {
              ...res,
              series_name: res.series_name + res.numerical_component,
              dates: `${from_date} ${to_date ? "-" + to_date : ""}`,
              region: res.regions.join(),
              rules: this.formatRules(res),
              channels:
                res.channels && Array.isArray(res.channels)
                  ? res.channels.join()
                  : res.channels,
            };
          });
          this.upcomingRows = this.upcomingCouponSeries.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPastCouponsSeries() {
      ApiService.post(
        "/coupons/coupon-series/past-series",
        this.cancellationPayload
      )
        .then((response) => {
          this.pastCouponSeries = response.data.data.map((res) => {
            let options = { day: "numeric", month: "short", year: "numeric" };
            let from_date = new Date(res.from_date);
            let to_date = res.to_date ? new Date(res.to_date) : "";
            from_date = from_date.toLocaleDateString("en-US", options);
            to_date = to_date
              ? to_date.toLocaleDateString("en-US", options)
              : "";

            return {
              ...res,
              series_name: res.series_name + res.numerical_component,
              dates: `${from_date} ${to_date ? "-" + to_date : ""}`,
              region: res.regions.join(),
              rules: this.formatRules(res),
              channels:
                res.channels && Array.isArray(res.channels)
                  ? res.channels.join()
                  : res.channels,
            };
          });
          this.pastRows = this.pastCouponSeries.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async delAll() {
      if (
        !this.selectedPastCoupon &&
        !this.selectedUpcomingCoupon &&
        !this.selectedActiveCoupon
      ) {
        return;
      }
      let allDeleteCoupons = [];
      if (this.findPayout == "active") {
        this.selectedActiveCoupon.map((item, index) => {
          allDeleteCoupons.push(item._id);
        });
      }
      if (this.findPayout == "upcoming") {
        this.selectedUpcomingCoupon.map((item, index) => {
          allDeleteCoupons.push(item._id);
        });
      }
      if (this.findPayout == "past") {
        this.selectedPastCoupon.map((item, index) => {
          allDeleteCoupons.push(item._id);
        });
      }

      try {
        await ApiService.post("/coupons/coupon-series/delete", {
          coupon_series_ids: allDeleteCoupons,
        });
        if (this.findPayout == "active") {
          this.getActiveCouponsSeries();
        }
        if (this.findPayout == "upcoming") {
          this.getUpcomingCouponsSeries();
        }
        if (this.findPayout == "past") {
          this.getPastCouponsSeries();
        }
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    },
    deleteAllConfirmation(event) {
      console.log("active coupon: ", this.selectedActiveCoupon);
      this.findPayout = event;
    },
    onSelectStartDate(ctx) {
      this.date = ctx.activeYMD;
    },
  },
  watch: {
    date: {
      handler: function() {
        this.cancellationPayload = {
          client_id: this.$route.params.id,
          month: this.date.split("-")[1],
          year: this.date.split("-")[0],
        };
        // this.getActiveCouponsSeries();
        // this.getPastCouponsSeries();
        // this.getUpcomingCouponsSeries();
      },
    },
    active_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getActiveCouponsSeries();
      },
    },
    past_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getPastCouponsSeries();
      },
    },
    upcoming_current_page: {
      handler: function() {
        // if (this.totalRecords <= 0) {
        //   return;
        // }
        this.getUpcomingCouponsSeries();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.col-auto {
  margin: auto;
  text-align: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
::v-deep .table {
  height: calc(100% - 25px);
  th,
  td {
    vertical-align: middle;
    padding: 10px;
  }
  thead {
    color: #000;
    th {
      text-transform: uppercase;
      padding-bottom: 15px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::after {
    box-shadow: 0 0 0 2px rgba(32, 33, 36, 0.122) !important;
    border: none !important;
  }

  .checkbox-xl .custom-control-label::before,
  .checkbox-xl .custom-control-label::after {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    opacity: 1;
    font-weight: 400;
    border: 2px solid #0000008a;
  }
  .b-avatar {
    margin-bottom: 8px;
    height: 25px;
    width: 25px;
  }
}
.logo {
  height: 85px;
  width: 80px;
  border-radius: 20px;
  border: 2px solid white;
  box-shadow: gainsboro 0px 0px 5px;
}
.card.card-custom.card-stretch.gutter-b {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.card.card-custom > .card-body {
  padding: 2rem 1em 2rem 2.29rem;
}
.card {
  border-radius: 20px;
}

/* Scroll bar style */
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff0d9;
    outline: 1px solid #fff0d9;
  }
}
.no-records {
  height: 400px;
  text-align: center;
  padding: 50px;
}
.deleteAll-btn {
  background: rgb(243, 14, 83);
  float: right !important;
  color: white;
  width: 120px !important;
  margin-bottom: 20px !important;
}

// for calender

.v-calendar {
  .v-event {
    text-align: center;
  }
}
.tab {
  margin-bottom: 10%;
  .title {
    font-weight: 600;
    margin-bottom: 1%;
  }
  .box {
    padding: 1.5rem;
    box-shadow: 0px 0px 10px 1px #d0cccc;
    border-radius: 10px;
    margin-top: 1rem;
    div {
      padding-bottom: 1.5rem;
    }
    input {
      margin-right: 1rem;
    }
    span {
      font-weight: 700;
    }
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
